@import "definitions";

.CodeMirror-hints {
  position: absolute;
  z-index: 10;
  overflow: hidden;

  -webkit-box-shadow: $main_box_shadow;
  -moz-box-shadow: $main_box_shadow;
  box-shadow: $main_box_shadow;
  
  border: 1px solid silver;

  background: white;
  font-size: 90%;

  max-height: 20em;
  overflow-y: auto;
}

.CodeMirror-hint {
  padding: 0.2em 0.5em;
  max-width: 19em;
  overflow: hidden;
  white-space: pre;
  color: $base_font_color;
  cursor: pointer;
}

li.CodeMirror-hint-active {
  background: $theme_color;
  color: $highlight_font_color;
}

.form_codemirror.readonly .CodeMirror-scroll{
  min-height:0;
}